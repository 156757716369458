// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import BTCLOGO from '../../assets/images/btc.png'
import ETHLOGO from '../../assets/images/eth.png'
import DitanicLogo from '../../assets/images/ditanic.png'
import UsdtLogo from '../../assets/images/usdt.png'
import DiverLogo from '../../assets/images/diver.svg'
import BattleTable from './BattleTable'
import { useBattleData } from 'state/battle/hooks'
import { getBtcPriceFromChainlink, getEthPriceFromChainlink, getPricesFromChainlink } from 'constants/infural'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { getQueryString, getTokenIcon, isMobile } from 'utils'
import BigNumber from 'bignumber.js'
import { formatDateUTC, getExpiringDates } from '../../utils/date'
import { LinkWrapper } from '../../components/pools/PoolTable'
import DivSelect from '../../components/DivSelect'
import { MenuItem } from '@mui/material'
import { defaultCollateralName, UNDERLYINGS } from '../../constants'
import { useQuery } from '../../hooks/useQuery'
import { ethers } from 'ethers'
import { getUnderlyingPrice } from '../../utils/prices'
import { UnderlyingIcon } from '../../components/underlying-icon'

dayjs.extend(utc)
dayjs.extend(weekday)

const BattleWrapper = styled.div`
  @media screen and (max-width: 1200px) {
    width: 90%;
  }
`
const Breadcrumbs = styled.div`
  font-size: 21px;
`
const UnderLying = styled.div`
  width: 219px;
  height: 49px;
  line-height: 49px;
  background: rgb(3 30 56);
  border: 1px solid #6a708b;
  border-left: 0;
  border-radius: 32px;
  font-size: 21px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  margin-left: 30px;
  div {
    background: linear-gradient(180deg, #06dfaf 0%, #0186ef 100%);
    border-radius: 14px;
    position: absolute;
    left: 100%;
    top: 1px;
    margin-left: -21px;
    padding: 0 20px 0 34px;
    z-index: -1;
    height: 46px;
    font-size: 18px;
    font-weight: bold;
  }
  span {
    top: -6px;
    left: -30px;
    width: 60px;
    border: 1px solid #fff;
    height: 60px;
    display: block;
    position: absolute;
    background: rgb(2 35 67);
    box-shadow: 0px 0px 14px rgb(62 176 253 / 65%) inset, 0 0 30px rgb(62 176 253 / 80%);
    padding-top: 9px;
    border-radius: 45px;
  }
  @media screen and (max-width: 480px) {
    min-width: 120px;
    padding-left: 20px;
    font-size: 16px;
    div {
      padding: 0 10px 0 24px;
    }
    span {
      left: -35px;
    }
  }
`
const SelectMain = styled.div`
  display: flex;
  margin-bottom: 40px;
  align-items: flex-end;
  select {
    outline-style: none;
    width: 359px;
    margin-right: 120px;
    background: transparent;
    font-size: 17px;
    color: #fff;
    border-radius: 6px;
    padding-left: 10px;
    height: 54px;
    border: 1px solid #6a708b;
    option {
      background: #f00;
      color: #fff;
    }
  }
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SelectFlex = styled.div`
  display: flex;
  > div:nth-child(1) {
    margin-right: 30px;
  }
  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    margin-bottom: 20px;
    > div:nth-child(1) {
      width: 45%;
      margin-right: 0;
    }
    > div:nth-child(2) {
      width: 45%;
    }
  }
`
const Select = styled.div`
  position: relative;
`

const options = []
options.push(
  ...[
    {
      name: 'USDT',
      icon: UsdtLogo,
      status: true,
    },
    {
      name: 'DIVER',
      icon: DiverLogo,
      status: true,
    },
    {
      name: defaultCollateralName,
      icon: DitanicLogo,
      status: true,
    },
    {
      name: 'WETH',
      icon: ETHLOGO,
      status: true,
    },
  ]
)
if (process.env.REACT_APP_TARGET_ENV === 'dev' || process.env.REACT_APP_TARGET_ENV === 'alpha') {
} else if (process.env.REACT_APP_TARGET_ENV === 'beta') {
}

export default function BattleInfo() {
  const [battles] = useBattleData()
  const routerParams = useParams()
  const query = useQuery()
  const chainId = query.get('c')
  const history = useHistory()
  const collateralSymbol = routerParams.collateral as string
  const [currentBattles, setCurrentBattles] = useState<Battle[]>([])
  const [pType, setPType] = useState(Number(routerParams.periodType))

  const provider = new ethers.providers.JsonRpcProvider(
    'https://arb-mainnet.g.alchemy.com/v2/l0JubG4AWmH0Whw69HnnBCdflBInS6k-'
  )

  const underlying = routerParams.underlying
  const expiringDates = getExpiringDates()
  const [expiringDate, setExpiringDate] = useState(expiringDates?.[0])

  const [selectVal, setSelectVal] = useState({
    name: routerParams.collateral,
    icon: getTokenIcon(routerParams.collateral),
    status: true,
  })

  const selectHandler = (key: number, e: any) => {
    setSelectVal(options[key])
    console.log(e)
  }

  console.log(battles, currentBattles, collateralSymbol, underlying, expiringDate)
  useEffect(() => {
    if (battles) {
      const filterBattles = battles.filter((f) => {
        return (
          f.collateralSymbol === collateralSymbol &&
          f.underlyingId === underlying &&
          f?.endTS == expiringDate &&
          new BigNumber(f.strikeValue).lt(1000000)
        )
      })
      filterBattles.sort((a, b) => (new BigNumber(a.strikeValue).lt(b.strikeValue) ? 1 : -1))
      setCurrentBattles(filterBattles)
    }
  }, [battles, collateralSymbol, underlying, expiringDate])

  const peroidChangeHandler = useCallback(
    (pt: number) => {
      setPType(pt)
      const newBattle = battles?.filter((b) => {
        return b.periodType === pt && b.collateralSymbol === selectVal.name && b.underlying === routerParams.underlying
      })
      if (newBattle) {
        // setCurrentBattles(newBattle)
      }
    },
    [battles, selectVal.name, routerParams.underlying]
  )

  useEffect(() => peroidChangeHandler(0), [])

  const [prices, setPrices] = useState<any>()

  useEffect(() => {
    getPricesFromChainlink(provider).then((d) => {
      console.log(d)
      setPrices(d)
    })
  }, [])

  const [currChain, setCurrChain] = useState('')
  useMemo(() => {
    if (getQueryString('c') !== '') {
      const chain = getQueryString('c')

      setCurrChain(chain)
    } else {
      setCurrChain('Arbitrum Rinkeby')
    }
  }, [])

  const filterUnderlyings = UNDERLYINGS.filter((u) => u !== underlying)

  return (
    <BattleWrapper>
      <Breadcrumbs>Battles</Breadcrumbs>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UnderLying>
          <span>
            <UnderlyingIcon name={underlying} width={40} height={40} />
          </span>
          {underlying}-USD
          <div>${getUnderlyingPrice(prices, routerParams?.underlying)}</div>
        </UnderLying>
        <LinkWrapper to={`/pools/0/${filterUnderlyings?.[0]}/0/${collateralSymbol}?c=${chainId}`}>
          <div
            style={{
              cursor: 'pointer',
              marginLeft:  isMobile ? '115px' : '140px',
              border: '1px solid #aaa',
              borderRadius: '30px',
              padding: '7px',
              width: '40px',
              height: '40px',

            }}
          >
            <UnderlyingIcon name={filterUnderlyings?.[0]} width={24} height={24} />
          </div>
        </LinkWrapper>
        <LinkWrapper to={`/pools/0/${filterUnderlyings?.[1]}/0/${collateralSymbol}?c=${chainId}`}>
          <div
            style={{
              cursor: 'pointer',
              marginLeft: isMobile ? '8px' : '20px',
              border: '1px solid #aaa',
              borderRadius: '30px',
              padding: '7px',
              width: '40px',
              height: '40px',
            }}
          >
            <UnderlyingIcon name={filterUnderlyings?.[1]} width={24} height={24} />
          </div>
        </LinkWrapper>
      </div>
      <SelectMain>
        <SelectFlex>
          <Select>
            <p>COLLATERAL</p>
            <DivSelect
              defaultValue={collateralSymbol}
              change={(event: any) => {
                history.push(`/pools/0/${underlying}/0/${event.target.value}?c=${chainId}`)
              }}
              styles={{
                width: '230px',
                height: '45px',
                justifyContent: 'center',
              }}
            >
              {options.map((item, key) => (
                <MenuItem key={`liquidity-collaterals-${key}`} value={item.name}>
                  <div key={key} onClick={selectHandler.bind(this, key)}>
                    <img src={item.icon} alt="icon" style={{ width: '23px' }} />
                    <span>{item.name}</span>
                  </div>
                </MenuItem>
              ))}
            </DivSelect>
          </Select>
        </SelectFlex>
        <DivSelect
          value={expiringDate}
          change={(event: any) => {
            setExpiringDate(Number(event.target.value))
          }}
          styles={{
            width: '230px',
            height: '45px',
            justifyContent: 'center',
          }}
        >
          {expiringDates.map((date, key) => (
            <MenuItem key={`liquidity-collaterals-${key}`} value={date}>
              {formatDateUTC(date)}
            </MenuItem>
          ))}
        </DivSelect>
      </SelectMain>
      <BattleTable poolDatas={currentBattles} />
    </BattleWrapper>
  )
}
