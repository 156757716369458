import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useBattleData } from 'state/battle/hooks'
import TransactionTable from './TransactionTable'
import ChartBox from './ChartBox'
import { getTokenIcon } from '../../utils'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import PicSpear from '../../assets/svg/spear.svg'
import { getPriceFromSqrtPriceX96 } from '@divergence-protocol/diver-sdk'
import PicShield from '../../assets/svg/shield.svg'
import useInterval from '../../hooks/useInterval'
import { Contract } from '@ethersproject/contracts'
import QuoterABI from '../../constants/abis/Quoter.json'
import { ethers } from 'ethers'
import { getBattleLiquidityArgs } from '../../utils/battle'
import { COLLATERALS } from '../../constants/collaterals'
import { isMobile } from 'react-device-detect'
import { flex } from 'styled-system'

const BattleWrapper = styled.div`
  @media screen and (max-width: 1200px) {
    width: 90%;
  }
`
const Breadcrumbs = styled.div`
  font-size: 21px;
  display: flex;
  align-items: center;
`

const IntroTitle = styled.div`
  display: flex;
  align-items: center;

  .coin-icon {
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }
  .coin-icon-box {
    display: flex;
    align-items: center;
    .coin-icon {
      position: relative;
      z-index: 1;
      &:last-child {
        z-index: 0;
        margin-left: -3px;
      }
    }
  }
  .coin-heading {
    margin-left: 10px;
    font-size: 25px;
    font-weight: bold;
  }
  .nameplate {
    width: 21px;
    height: 21px;
    margin-left: 6px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }
`
const PanelBox = styled.div`
  width: 1280px;
  display: flex;
  .aside {
    width: 310px;
    margin-right: 40px;
    flex-shrink: 0;
  }
  .banner-wrap {
    flex-grow: 1;
    margin-top: 20px;
  }
`

const AsideBaseDesc = styled.div`
  margin-top: 30px;
  font-size: 16px;
  margin-bottom: 26px;
  > div {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  img {
    width: 24px;
    height: 24px;
    margin: 0 6px;
  }
`
const AsidePanel = styled.div`
  height: 400px;
  padding: 12px;
  margin-top: 21px;
  border-radius: 12px;
  background-color: rgba(216, 216, 216, 0.04);
`

const AsidePanelDetail = styled.div`
  padding-left: 20px;
  padding-top: 20px;
  .group {
    .label {
      font-size: 16px;
    }
    .value {
      font-size: 25px;
      font-weight: bold;
      line-height: 1;
    }
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
`

const TradeBtn = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 44px;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  background-image: linear-gradient(90deg, rgba(6, 223, 175, 0.6) 0%, rgba(1, 134, 239, 0.6) 100%);
  font-size: 14px;
  font-weight: 600;
  font-family: 'Roboto';
  cursor: pointer;
`

const AddLiquidityBtn = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 44px;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  background: #3a5773;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Roboto';
  cursor: pointer;
`

const BannerWarp = styled.div`
  height: 400px;
  border-radius: 12px;
  background-color: rgba(216, 216, 216, 0.04);
  padding-top: 20px;
`

const TransactionsBox = styled.div`
  margin-top: 52px;
  padding-left: 15px;
`
const TransactionsHeading = styled.div`
  font-size: 20px;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 20px;
`

export default function Detail() {
  const [battles] = useBattleData()
  const routerParams = useParams<{ id: string }>()
  const battleId = routerParams.id as string

  const battle = useMemo(() => battles?.find((battle) => battle.address === battleId), [battles])

  const [spearT, setSpearT] = useState('0')
  const [shieldT, setShieldT] = useState('0')

  const provider = new ethers.providers.JsonRpcProvider(
    'https://arb-mainnet.g.alchemy.com/v2/l0JubG4AWmH0Whw69HnnBCdflBInS6k-'
  )
  const quoterContract = new Contract('0xDFc9049aF4f94305a1485849e7B39f3A18120680', QuoterABI, provider)
  useInterval(async () => {
    try {
      const spearQ = await quoterContract?.callStatic.quoteExactInput(getBattleLiquidityArgs(0), battle?.address)
      const shieldQ = await quoterContract?.callStatic.quoteExactInput(getBattleLiquidityArgs(1), battle?.address)
      console.log(spearQ?.get?.toString(), shieldQ?.get?.toString())
      setSpearT(spearQ?.get?.toString())
      setShieldT(shieldQ?.get.toString())
    } catch (e) {
      console.error('get all liquidity', e)
    }
  }, 20000)

  console.log(battle)
  const collateral = COLLATERALS.find((c) => c.name === battle?.collateralSymbol)

  return (
    <BattleWrapper>
      <Breadcrumbs>
        <IntroTitle>
          <div className="coin-icon-box">
            <div className="coin-icon">
              <img src={getTokenIcon(battle?.underlyingId)} />
            </div>
          </div>
          <div className="coin-heading">
            {battle?.underlyingId}-USD {battle?.strikeValue}
          </div>
        </IntroTitle>
      </Breadcrumbs>

      {isMobile ? (
        <AsideBaseDesc>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
              Collateral: <img src={getTokenIcon(battle?.collateralSymbol)} alt="" />
              {battle?.collateralSymbol}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginLeft: 'auto' }}>
              <TradeBtn
                style={{ width: 'auto', padding: '0 20px', textAlign: 'center' }}
                target={'_blank'}
                href={`https://app.divergence-protocol.com?underlying=${battle?.underlyingId}&expiring_date=${
                  battle?.endTS
                }&strikeValue=${new BigNumber(battle?.strikeValue ?? '0')
                  .multipliedBy(10 ** 18)
                  .toFixed()}&collateral=${collateral?.address}`}
              >
                Trade
              </TradeBtn>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ maxWidth: '100px' }}>
              {dayjs.utc((battle?.endTS ?? 0) * 1000).format('MMM D, YYYY HH:mm') + ' UTC'}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', fontSize: '14px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                  <img width="16px" src={PicSpear} style={{ marginRight: '5px' }} />
                  {new BigNumber(1).minus(getPriceFromSqrtPriceX96(battle?.sqrtPriceX96 ?? '0')).toFixed(6)}
                </div>
                <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img width="16px" src={PicShield} style={{ marginRight: '5px' }} />
                  {getPriceFromSqrtPriceX96(battle?.sqrtPriceX96 ?? '0').toFixed(6)}
                </div>
              </div>
            </div>
          </div>
        </AsideBaseDesc>
      ) : (
        <AsideBaseDesc>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
              Collateral: <img src={getTokenIcon(battle?.collateralSymbol)} alt="" />
              {battle?.collateralSymbol}
            </div>
            <div>Expiration Date: {dayjs.utc((battle?.endTS ?? 0) * 1000).format('MMM D, YYYY, HH:mm') + ' UTC'}</div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', fontSize: '14px' }}>
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="16px" src={PicSpear} style={{ marginRight: '5px' }} />
                {new BigNumber(1).minus(getPriceFromSqrtPriceX96(battle?.sqrtPriceX96 ?? '0')).toFixed(6)}
              </div>
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="16px" src={PicShield} style={{ marginRight: '5px' }} />
                {getPriceFromSqrtPriceX96(battle?.sqrtPriceX96 ?? '0').toFixed(6)}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginLeft: 'auto' }}>
              <TradeBtn
                target={'_blank'}
                href={`https://app.divergence-protocol.com/?underlying=${battle?.underlyingId}&expiring_date=${
                  battle?.endTS
                }&strikeValue=${new BigNumber(battle?.strikeValue ?? '0')
                  .multipliedBy(10 ** 18)
                  .toFixed()}&collateral=${collateral?.address}`}
              >
                Trade
              </TradeBtn>
            </div>
          </div>
        </AsideBaseDesc>
      )}
      <PanelBox style={{ display: isMobile ? 'block' : 'flex', width: isMobile ? 'auto' : '1280px' }}>
        <div className="aside" style={{ width: isMobile ? '100%' : '310px' }}>
          <AsidePanel>
            <AsidePanelDetail>
              <div
                style={{
                  background: '#071f32',
                  borderRadius: '12px',
                  padding: '20px',
                  marginRight: '20px',
                  fontWeight: 500,
                }}
              >
                <div className="label" style={{ marginBottom: '10px' }}>
                  Options Liquidity
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img width="16px" src={PicSpear} style={{ marginRight: '5px' }} />
                    Spear
                  </div>
                  <div>{new BigNumber(spearT)?.div(10 ** 18).toFixed(6)}</div>
                </div>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img width="16px" src={PicShield} style={{ marginRight: '5px' }} />
                    Shield
                  </div>
                  <div>{new BigNumber(shieldT)?.div(10 ** 18).toFixed(6)}</div>
                </div>
              </div>
              <div className="group">
                <div className="label">TVL</div>
                <div className="value">${new BigNumber(battle?.totalCollateralUSD ?? 0).toFormat(2)}</div>
              </div>
              <div className="group">
                <div className="label">Volume 24h</div>
                <div className="value">${new BigNumber(battle?.volumeUSD24H ?? 0).toFormat(2)}</div>
              </div>
              <div className="group">
                <div className="label">Fees 24h</div>
                <div className="value">${new BigNumber(battle?.feesUSD24H ?? 0).toFormat(2)}</div>
              </div>
            </AsidePanelDetail>
          </AsidePanel>
        </div>
        <div className="banner-wrap">
          <BannerWarp style={{ width: isMobile ? '100%' : 'auto', height: isMobile ? '430px' : '400px' }}>
            <ChartBox battle={battle} />
          </BannerWarp>
        </div>
      </PanelBox>
      <TransactionsBox>
        <TransactionsHeading>Transactions</TransactionsHeading>
        <TransactionTable battle={battle} />
      </TransactionsBox>
    </BattleWrapper>
  )
}
