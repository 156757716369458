import dayjs from 'dayjs'

export function unixToDate(unix: number, format = 'YYYY-MM-DD'): string {
  return dayjs.unix(unix).format(format)
}

export const formatTime = (unix: string) => {
  const now = dayjs()
  const timestamp = dayjs.unix(parseInt(unix))

  const inSeconds = now.diff(timestamp, 'second')
  const inMinutes = now.diff(timestamp, 'minute')
  const inHours = now.diff(timestamp, 'hour')
  const inDays = now.diff(timestamp, 'day')

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? 'day' : 'days'} ago`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? 'hour' : 'hours'} ago`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? 'minute' : 'minutes'} ago`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? 'second' : 'seconds'} ago`
  }
}

export const getExpirationDate = () => {
  const current = dayjs().unix()
  let tick = 1684483200
  while (current > tick) {
    tick += 7 * 24 * 3600
  }

  return tick
}

export const getExpirationDateByDay = () => {
  const current = dayjs().unix()
  let tick = 1684483200
  while (current > tick) {
    tick += 1 * 24 * 3600
  }

  return tick
}

export const formatDateUTC = (timestamp: number | undefined) => {
  if (!timestamp) return
  return dayjs.utc(timestamp * 1000).format('MMM D, YYYY, HH:mm') + ' UTC'
}

export const getExpiringDates = () => {
  const dates = [getExpirationDate(), getExpirationDateByDay()]
  dates.sort((a, b) => a - b)
  return dates.filter((item, index) => dates.indexOf(item) === index)
}
