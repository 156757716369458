import dayjs from 'dayjs'
import { ethers } from 'ethers'
import OracleAbi from './abis/Oracle.json'
import utc from 'dayjs/plugin/utc'
import BigNumber from 'bignumber.js'
dayjs.extend(utc)
export const INFURA_URL = 'https://kovan.infura.io/v3/082a3def8d524dfc9f43dad330a2a399'

export const INFURA_KEY = '082a3def8d524dfc9f43dad330a2a399'
export const ORACLE_ADDR = '0x7Ad23ff84d6840867f6EbBb95e1b00444910f027'

export async function getOracle() {
  const provider = new ethers.providers.InfuraProvider('kovan', INFURA_KEY)
  const oracle = new ethers.Contract(ORACLE_ADDR, OracleAbi, provider)
  const tsDay: string[] = (await oracle.getRoundTS(0)).map((ts: any) => {
    return ts.toNumber() //.dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  // console.log(start.toNumber(), start, start)
  const tsWeek: string[] = (await oracle.getRoundTS(1)).map((ts: any) => {
    return ts.toNumber() // dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  const tsMon: string[] = (await oracle.getRoundTS(2)).map((ts: any) => {
    return ts.toNumber() // dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  return [tsDay, tsWeek, tsMon]
}

export async function getBTCPrice(coin: string) {
  const resp = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=usd`)
  const data = await resp.json()
  return data[coin]['usd']
}

const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: 'latestRoundData',
    outputs: [
      { internalType: 'uint80', name: 'roundId', type: 'uint80' },
      { internalType: 'int256', name: 'answer', type: 'int256' },
      { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
      { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
      { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

export const getPricesFromChainlink = async (provider: any) => {
  const btcContract = new ethers.Contract(
    '0x6ce185860a4963106506C203335A2910413708e9',
    aggregatorV3InterfaceABI,
    provider
  )
  const ethContract = new ethers.Contract(
    '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
    aggregatorV3InterfaceABI,
    provider
  )
  const dogeContract = new ethers.Contract(
    '0x9A7FB1b3950837a8D9b40517626E11D4127C098C',
    aggregatorV3InterfaceABI,
    provider
  )
  const res = await Promise.all([
    btcContract.latestRoundData(),
    ethContract.latestRoundData(),
    dogeContract.latestRoundData(),
    fetch(
      'https://hermes.pyth.network/api/latest_price_feeds?ids%5B%5D=6f9cd89ef1b7fd39f667101a91ad578b6c6ace4579d5f7f285a4b06aa4504be6'
    ).then((r) => r.json()),
  ])
  console.log('price', res)
  return [
    { symbol: 'btc', price: Number(new BigNumber(res?.[0]?.answer?.toString()).div(10 ** 8).toFixed(2)) },
    {
      symbol: 'eth',
      price: Number(new BigNumber(res?.[1]?.answer?.toString()).div(10 ** 8).toFixed(2)),
    },
    {
      symbol: 'doge',
      price: Number(new BigNumber(res?.[2]?.answer?.toString()).div(10 ** 8).toFixed(3)),
    },
    {
      symbol: 'gme',
      price: Number(new BigNumber(res?.[3]?.[0]?.price?.price).div(10 ** 5).toFixed(2)),
    },
  ]
}

export const getBtcPriceFromChainlink = async (provider: any) => {
  const btcContract = new ethers.Contract(
    '0x6ce185860a4963106506C203335A2910413708e9',
    aggregatorV3InterfaceABI,
    provider
  )
  const res = await Promise.all([btcContract.latestRoundData()])

  return Number(new BigNumber(res?.[0]?.answer?.toString()).div(10 ** 8).toFixed(2))
}

export const getEthPriceFromChainlink = async (provider: any) => {
  const ethContract = new ethers.Contract(
    '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
    aggregatorV3InterfaceABI,
    provider
  )
  const res = await Promise.all([ethContract.latestRoundData()])
  return Number(new BigNumber(res?.[0]?.answer?.toString()).div(10 ** 8).toFixed(2))
}
