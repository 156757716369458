import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { ellipseAddress, getEtherscanLink, getQueryString } from 'utils'
import { ClickableText, Label } from 'components/Text'
import { Arrow, Break, PageButtons } from 'components/shared'
import useTheme from 'hooks/useTheme'
import BTCPic from 'assets/images/btc.png'
import ETHPic from 'assets/images/eth.png'
import { Battle, Transaction } from '../../types/battle'
import { getTransactionEventsByType, getTransactionEventText, isTradeTransaction, TransactionEvent } from '../../types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import BigNumber from 'bignumber.js'
import { isMobile } from 'react-device-detect'

dayjs.extend(relativeTime)

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  overflow-x: auto;
`

const ResponsiveGrid = styled.div`
  min-width: 700px;
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 260px repeat(4, 1fr);

  @media screen and (max-width: 480px) {
     grid-template-columns: 260px repeat(1, 1fr);
  }

  // @media screen and (max-width: 900px) {
  //   grid-template-columns: 15px 1.5fr repeat(4, 1fr);
  //   & :nth-child(3) {
  //     display: none;
  //   }
  // }

  // @media screen and (max-width: 500px) {
  //   grid-template-columns: 15px 1.5fr repeat(3, 1fr);
  //   & :nth-child(6) {
  //     display: none;
  //   }
  // }

  // @media screen and (max-width: 480px) {
  //   grid-template-columns: 15px repeat(3, 1fr);
  //   & :nth-child(3) {
  //     display: none;
  //   }
  //   & :nth-child(6) {
  //     display: none;
  //   }
  // }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const UnderlyingIcon = styled.img`
  margin-right: 10px;
  position: relative;
  top: 1px;
`

const SORT_FIELD = {
  // feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  liquidityUSD: 'liquidityUSD',
  volumeUSD24H: 'volumeUSD24H',
  volumeUSD7D: 'volumeUSD7D',
  // volumeUSDWeek: 'volumeUSDWeek',
}
// @ts-ignore
const returnIcon = (name: string) => {
  switch (name) {
    case 'BTC':
      return BTCPic
    case 'ETH':
      return ETHPic
  }
}

// const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
const DataRow = ({ transaction, index }: { transaction: Transaction; index: number }) => {
  const [currChain, setCurrChain] = useState('')
  useMemo(() => {
    if (getQueryString('c') !== '') {
      const chain = getQueryString('c')
      setCurrChain(chain)
    } else {
      setCurrChain('Arbitrum Rinkeby')
    }
  }, [])

  const showTotalAmount = (tx: Transaction) => {
    if (isTradeTransaction(tx.event)) {
      return
    }
  }

  return (
    <div>
      <ResponsiveGrid style={{ minWidth: isMobile?'auto':'700px' }}>
        <Label style={{ width: '200px' }} fontWeight={400}>
          <a
            style={{ color: '#2db4ff', textDecoration: 'none' }}
            href={getEtherscanLink(421613, transaction?.hash, 'transaction')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getTransactionEventText(transaction.event)}
          </a>
        </Label>
        {!isMobile&&<>
          <Label end={1}>{transaction.totalValue}</Label>
          <Label end={1}>{transaction.totalAmount}</Label>
          <Label end={1}>
            <a
              style={{ color: '#2db4ff', textDecoration: 'none' }}
              href={getEtherscanLink(421613, transaction?.hash, 'address')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ellipseAddress(transaction.account)}
            </a>
          </Label>
        </>}
        <Label end={1} style={{ whiteSpace: 'nowrap' }}>{dayjs(transaction.time * 1000).fromNow()}</Label>
      </ResponsiveGrid>
    </div>
  )
}

const MAX_ITEMS = 10

export default function PoolTable({ battle, maxItems = MAX_ITEMS }: { battle: Battle | undefined; maxItems?: number }) {
  const theme = useTheme()
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [type, setType] = useState('all')

  useEffect(() => {
    if (battle) {
      let extraPages = 1
      if (battle.transactions.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(battle.transactions.length / maxItems) + extraPages)
    }
  }, [maxItems, battle])

  const txs = [...(battle?.transactions ?? [])]
  console.log(txs)
  const sortedTxs = txs.sort((a: Transaction, b: Transaction) => (a.time < b.time ? 1 : -1))
  console.log(sortedTxs)
  const filterTxs1 = sortedTxs.filter((tx) => getTransactionEventsByType(type).includes(tx.event))
  const filterTxs = filterTxs1.slice((page - 1) * MAX_ITEMS, page * MAX_ITEMS)
  console.log(filterTxs)

  if (!battle) {
    return <Loader />
  }

  return (
    <Wrapper>
      {battle?.transactions.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid style={{ minWidth: isMobile?'auto':'700px' }}>
            <Label style={{ width: '260px', fontSize: '14px', fontWeight: 'bold' }}>
              <ClickableText style={{ marginRight: '20px' }} color={theme.text3} onClick={() => setType('all')}>
                All
              </ClickableText>
              <ClickableText style={{ marginRight: '20px' }} color={theme.text3} onClick={() => setType('trade')}>
                Trades
              </ClickableText>
              <ClickableText style={{ marginRight: '20px' }} color={theme.text3} onClick={() => setType('add')}>
                Adds
              </ClickableText>
              <ClickableText style={{ marginRight: '20px' }} color={theme.text3} onClick={() => setType('remove')}>
                Removes
              </ClickableText>
              <ClickableText style={{ marginRight: '20px' }} color={theme.text3} onClick={() => setType('redeem')}>
                Redeems
              </ClickableText>
            </Label>
            {!isMobile&&<>
              <Label end={1} style={{ fontSize: '14px' }} color={theme.text3}>
                Total Value
              </Label>
              <Label end={1} style={{ fontSize: '14px' }} color={theme.text3}>
                Token Amount
              </Label>
              <Label end={1} style={{ fontSize: '14px' }} color={theme.text3}>
                Account
              </Label>
            </>}
            <Label end={1} style={{ fontSize: '14px' }} color={theme.text3}>
              Time
            </Label>
          </ResponsiveGrid>
          <Break />
          {filterTxs &&
            filterTxs.map((transaction, i) => {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} transaction={transaction} />
                  <Break />
                </React.Fragment>
              )
              return null
            })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
