import { fetchBattleData } from 'data/battle'
import { useEffect, useState } from 'react'
import { useBattleData } from './hooks'
import useRefresh from '../../hooks/useRefresh'

export default function Updater(): null {
  const { fastRefresh } = useRefresh()
  const [battleData, updateBattleData] = useBattleData()
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetch() {
      const { data: fetchedBattleData, error, anyLoading } = await fetchBattleData()
      if (!error && !anyLoading && fetchedBattleData) {
        updateBattleData(fetchedBattleData)
      }
      if (error) {
        setError(error)
      }
    }
    if (!error) {
      fetch()
    }
  }, [fastRefresh])
  return null
}
