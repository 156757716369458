import { Box, createTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import bodyBg from '../assets/images/global_bg.png'

export const theme = createTheme({
  spacing: 5,
  palette: {
    primary: {
      main: '#06DFAF',
    },
    secondary: {
      main: '#FD7C25',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '360px',
          maxWidth: '100%',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: `
            display: none;
          `,
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: 'rgba(88, 88, 88, .2)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1,
            borderColor: 'rgba(151, 151, 151, 0.21) !important',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff !important',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: `
          .MuiMenu-paper {
            background: #08172b;
            box-shadow: 0px 1px 24px 7px rgba(0, 0, 0, 0.41) !important;
            margin-top: 5px;
            ul {
              font-size: 14px;
              color: #fff;
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                div,
                p {
                  display: flex;
                  align-items: center;
                  line-height: 13px;
                }
              }
              img {
                margin-right: 10px;
              }
            }
          }
        `,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          * {
            box-sizing: border-box;
            margin: 0;
            padding 0;
          }
          ::-webkit-calendar-picker-indicator {
            filter: invert(1);
          };
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          ::-webkit-scrollbar-button {
            display: none;
          }
          ::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.39);
            border-radius: 4px;
          }
          ::-webkit-scrollbar-track {
            background:  #121a27;
            border-radius: 10px;
            margin: 4px;
          }
          ::-webkit-scrollbar-thumbhover {
            background: #373d57;
          }
          ::-webkit-scrollbar-corner {
            background: transparent;
          }
          html {
            WebkitFontSmoothing: auto;
            overflow-y: overlay;
          }
          body {
            overflow: auto !important;
            background: linear-gradient(#002a46, #0a0813);
            background-size: 100% 100%;
            min-height: 100vh;
            font-size: 12px;
            position: relative;
            color: #fff;
            -webkit-scrollbar {
              width: 4px;
            }
            -webkit-scrollbar-track {
              background: #121a27;
              border-radius: 2px;
            }
            -webkit-scrollbar-thumb {
              background: rgba(255, 255, 255, 0.39);
              border-radius: 4px;
            }
            -webkit-scrollbar-thumbhover {
              background: #373d57;
            }
          }
          a {
            text-decoration: none;
          }
          .grayText {
            color: rgba(255, 255, 255, 0.72);
          }
          .home_slider {
            & .MuiSlider-thumb {
              display: none;
            }
            & .MuiSlider-rail {
              opacity: 1;
              background: linear-gradient(90deg #E23F96 0% rgba(226 63 150 0.5) 100%);
            }
            & .MuiSlider-track {
              background: linear-gradient(90deg #0C8E72 0% #06DFAF 100%);
            }
          }
          .MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
            box-shadow: none;
            border-radius: 28px;
            padding: 36px 55px;
            width: 421px;
            color: #FFFFFF !important;
            background: rgba(0, 0, 0, 0.4);
            @media (max-width: 768px) {
              width: 100%;
              padding: 36px 55px;
            }
          }
          .MuiContainer-root {
            @media (max-width: 768px) {
              padding-left: 20px;
              padding-right: 20px;
            }
          }
          .MuiMenuItem-root {
            font-size: 14px !important;
          }
          .MuiDialog-root {
            background: linear-gradient(to bottom rgb(0 41 79 / 60%), rgb(10 16 21 / 60%));
            .MuiDialog-paper {
              color: #FFF;
              box-shadow: none;
              max-width: 421px;
              border: 1px double transparent;
              background-image: linear-gradient(#08151f, #08151f) !important;
              background-origin: border-box;
              background-clip: border-box;
              border-radius: 14px;
            }
          }
          .MuiDialog-paper {
            overflow-y: inherit !important;
          }
          .MuiSlider-thumb.Mui-focusVisible .MuiSlider-thumbhover {
            box-shadow: 0 0 12px rgb(62 176 253 / 35%) inset 0 0 8px rgb(62 176 253 / 50%) !important;
          }
          .wallets-dialog .MuiBackdrop-root {
            background: url(${bodyBg}) no-repeat;
            background-size: 100% 100%;
          }
          .MuiButton-containedPrimary {
            color: #fff !important;
          }
          .MuiPaper-root.MuiAccordion-root {
            background :transparent;
            color: #fff;
          }
          .MuiAccordion-rootbefore {
            opacity: 0;
          }
          .MuiPaper-elevation1 {
            box-shadow: none;
          }
          .MuiButtonBase-root.MuiAccordionSummary-root.Mui-disabled {
            color: rgba(255, 255, 255, 0.39);
            opacity: 1;
          }
          .MuiAccordionDetails-root {
            border-top: 1px solid #333A50;
            position: relative;
          }
          .MuiSnackbarContent-root {
            background: #181F2B !important;
            padding: 1px 16px 6px !important;
          }
          .MuiSnackbar-anchorOriginTopRight {
            position: absolute !important;
            right: 0 !important;
            top: 0 !important;
          }
          .MuiAccordionSummary-expandIcon {
            width: 42px;
          }
          #dof {
            overflow: hidden !important;
          }
          #body {
            overflow: auto !important;
          }
          .MuiAccordionSummary-root.Mui-expanded {
            min-height: 48px !important;
            height: 48px !important;
          }
          // .MuiTableCell-root {
          //   padding: 20px 0 !important;
          // }
          .no_options {
            color: rgba(255, 255, 255, 0.39);
          }
          #simple-menu .MuiPaper-root {
            background: #08172b;
            margin-top: -21px;
            margin-left: 53px;
            padding-bottom: 30px;
            border-radius: 28px;
          }
          #selectStatus-menu .MuiPaper-root {
            background: #08172b;
            color: #fff;
            margin-top: 40px;
          }
          input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
            appearance: none !important;
          }
          @keyframes loadingAnimation {
            0% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
          @keyframes divloader {
            0% {
              transform :scale(1);
            }
            60% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
          .MuiTooltip-popper {
            pointer-events: auto !important;
          }
          .MuiTablePagination-root {
            color: #fff !important;
          }
          .MuiIconButton-root.Mui-disabled {
            color: rgb(255 255 255 / 26%) !important;
          }
          .MuiTablePagination-spacer {
            display: none;
          }
          .MuiTablePagination-toolbar {
            justify-content: center;
          }
          .MuiTab-textColorPrimary {
            color: #fff !important;
          }
          .global-slider {
            .MuiSlider-rail {
              opacity: 1;
              background: linear-gradient(90deg, #a44348 0%, rgb(166 68 72 / 50%) 100%);
            }
            .MuiSlider-thumb {
              background: #2C2F40;
              border: 2px solid #fff;
              width: 15px;
              height: 15px;
              &:hover {
                boxShadow: none;
              }
            }
            .MuiSlider-active {
              boxShadow: none;
            }
          },
          .holdingsTabs {
            border-bottom: none;
            position: relative;
            top: 3px;
            background: linear-gradient(to bottom #000d19 #000d19);
            border-radius: 14px 14px 0 0;
            & .MuiTabs-flexContainer {
              justify-content: center;
            }
            & button {
              min-width: auto !important;
              text-transform: capitalize !important;
            }
          }
          .intableIcon {
            position: relative;
            top: 2px;
            margin-right: 10px;
          }
          .MuiSelect-icon {
            color: #fff !important;
          }
          .MuiCheckbox-root {
            color: #06DFAF !important;
          }
          .MuiCheckbox-colorSecondary.Mui-checked {
            color: #06DFAF !important;
          }
          .tab-panel {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 0 0 30px 30px;
          }
          .MuiPopover-paper {
            background: #031a28 !important;
          }
        `,
    },
  },
})

export const InputMain = styled(Box)({
  height: '45px',
  borderRadius: '8px',
  // display: 'flex',
  border: '1px solid rgba(255, 255, 255, 0.39)',
  '& input': {
    paddingLeft: '10px',
    lineHeight: '55px',
    fontSize: '16px',
    color: '#fff',
    outline: 'none',
    background: 'transparent',
    border: 0,
    flex: 1,
    width: '160px',
  },
  '& > span': {
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 500,
    color: '#429BFF',
    lineHeight: '20px',
    background: '#2C3450',
    borderRadius: '4px',
    display: 'inline-block',
    height: '20px',
    textAlign: 'center',
    marginTop: '15px',
    marginRight: '10px',
    padding: '0 5px',
  },
})

export const LoadingRows = styled('div')({
  display: 'grid',
  minWidth: '75%',
  maxWidth: '100%',
  padding: '0 14px',
  marginTop: '10px',
  gridColumnGap: '0.5em',
  gridRowGap: '0.8em',
  gridTemplateColumns: 'repeat(3, 1fr)',
  '& > div': {
    animation: 'loadingAnimation 1.5s infinite',
    animationFillMode: 'both',
    background: 'linear-gradient( to left, #1F2128 25%, #2C2F36 50%, #40444F 75%)',
    backgroundSize: '400%',
    borderRadius: '8px',
    height: '2.4em',
    willChange: 'backgroundPosition',
  },
  '& > div:nth-of-type(4n + 1)': {
    gridColumn: '1 / 3',
  },
  '& > div:nth-of-type(4n)': {
    gridColumn: '3 / 4',
    marginBottom: '2em',
  },
})

export const DivUnderlying = styled(Box)({
  maxWidth: '280px',
  fontSize: '20px',
  fontWeight: 600,
  color: '#fff',
  lineHeight: '30px',
  borderRadius: '32px',
  border: '1px solid rgba(255, 255, 255, 0.39)',
  padding: '5px 0',
  position: 'relative',
  textAlign: 'center',
  '@media (max-width:768px)': {
    margin: '10px 65px 25px 95px',
  },
  '& div': {
    boxShadow: '0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%)',
    width: '50px',
    height: '50px',
    background: '#02253c',
    borderRadius: '45px',
    border: '1px solid #b1cae1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-6px',
    left: '-10px',
  },
})
