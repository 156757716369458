import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import BarChart from 'components/BarChart/alt'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { useArenaData } from 'state/arena/hooks'
import { MonoSpace } from 'components/shared'
import useTheme from 'hooks/useTheme'
import { formatDollarAmount } from 'utils/numbers'
import { unixToDate } from 'utils/date'
import { Battle } from '../../types/battle'
import { isMobile } from 'react-device-detect'

const ChartWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  padding: 0 10px;
`};
`
const TabBar = styled.div`
  right: 30px;
  top: 5px;
  position: absolute;
  width: 720px;
  height: 34px;
  padding: 4px;
  border-radius: 14px;
  background-color: rgba(235, 237, 244, 0.07);
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  position: relative;
  right: 0;
`};
`
const TabBarItem = styled.div`
  letter-spacing: 3px;
  min-width: 90px;
  max-width: 380px;
  padding: 0 20px;
  height: 26px;
  align-items: stretch;
  line-height: 26px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  border-radius: 13px;
  cursor: pointer;
  &.active {
    background-color: rgb(11, 13, 14);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  letter-spacing: 2px;
  min-width: 0;
  padding: 0 10px;
`};
`

export default function ChartBox({ battle }: { battle: Battle | undefined }) {
  const theme = useTheme()
  const [index, setIndex] = useState(0)
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  console.log('battle', battle)
  const data = useMemo(() => {
    if (index === 0) return battle?.spearOpenInterestByDay
    if (index === 1) return battle?.shieldOpenInterestByDay
    if (index === 2) return battle?.volumeByDay
    return battle?.feesByDay
  }, [battle, index])

  useEffect(() => {
    if (!volumeHover && battle) {
      setVolumeHover(data[0].value)
    }
  }, [battle])

  useEffect(() => {
    if (battle) {
      setVolumeHover(data[0].value)
    }
  }, [index])

  return (
    <ChartWrapper>
       {isMobile?
        <TabBar>
          <TabBarItem onClick={() => setIndex(0)} className={index === 0 ? 'active' : ''}>
            SPEAR
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(1)} className={index === 1 ? 'active' : ''}>
            SHIELD
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(2)} className={index === 2 ? 'active' : ''}>
            VOLUME
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(3)} className={index === 3 ? 'active' : ''}>
            FEES
          </TabBarItem>
        </TabBar>:
        <TabBar>
          <TabBarItem onClick={() => setIndex(0)} className={index === 0 ? 'active' : ''}>
            SPEAR OPEN INTEREST
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(1)} className={index === 1 ? 'active' : ''}>
            SHIELD OPEN INTEREST
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(2)} className={index === 2 ? 'active' : ''}>
            VOLUME
          </TabBarItem>
          <TabBarItem onClick={() => setIndex(3)} className={index === 3 ? 'active' : ''}>
            FEES
          </TabBarItem>
        </TabBar>
      }
      <BarChart
        height={380}
        minHeight={380}
        data={data}
        color={theme.blue1}
        setValue={setVolumeHover}
        setLabel={setRightLabel}
        value={volumeHover}
        label={rightLabel}
        topLeft={
          <AutoColumn gap="4px">
            {/* <TYPE.mediumHeader fontSize="14px">Volume 24H</TYPE.mediumHeader> */}
            <TYPE.largeHeader fontSize="35px" style={{fontSize:isMobile?'18px':'35px',marginTop:isMobile?'15px':''}}>
              <MonoSpace>
                {index < 2 ? formatDollarAmount(volumeHover, 2).replace('$', '') : formatDollarAmount(volumeHover, 2)}
              </MonoSpace>
            </TYPE.largeHeader>
            <TYPE.main fontSize="12px" height="14px">
              {rightLabel ?? ''}
              {/* {rightLabel ? (
                    <MonoSpace>{rightLabel}</MonoSpace>
                  ) : (
                    <MonoSpace>{dayjs.utc().format('MMM D, YYYY')}</MonoSpace>
                  )} */}
            </TYPE.main>
          </AutoColumn>
        }
      />
    </ChartWrapper>
  )
}
