import { getSqrtPriceX96FromPrice } from '@divergence-protocol/diver-sdk'
import { ethers } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'

export const getBattleLiquidityArgs = (tradeType: number) => {
  const sqrtX96Price = getSqrtPriceX96FromPrice(0)
  return {
    recipient: ethers.constants.AddressZero,
    tradeType: tradeType,
    amountSpecified: parseUnits('1000000000000000000', 18).toString(),
    sqrtPriceLimitX96: sqrtX96Price.toFixed(),
    data: ethers.utils.formatBytes32String(''),
  }
}
