export interface Block {
  number: number
  timestamp: string
}

export interface ChartDayData {
  date: number
  totalVolumeUSD: number
  totalAmountUSD: number
  feesUSD?: number
  spearOpenInterest?: number
  shieldOpenInterest?: number
}

export enum TransactionType {
  SWAP,
  MINT,
  BURN,
}

export type Transaction = {
  type: TransactionType
  hash: string
  timestamp: string
  sender: string
  token0Symbol: string
  token1Symbol: string
  token0Address: string
  token1Address: string
  amountUSD: number
  amountToken0: number
  amountToken1: number
}

/**
 * Formatted type for Candlestick charts
 */
export type PriceChartEntry = {
  time: number // unix timestamp
  open: number
  close: number
  high: number
  low: number
}

export enum TransactionEvent {
  MINT, // add
  BURN, // remove
  BUY_SPEAR,
  BUY_SHIELD,
  REDEEM,
}

export const getTransactionEventText = (event: number) => {
  if (event === TransactionEvent.MINT) return 'Mint'
  if (event === TransactionEvent.BURN) return 'Burn'
  if (event === TransactionEvent.REDEEM) return 'Redeem'
  if (event === TransactionEvent.BUY_SPEAR || event === TransactionEvent.BUY_SHIELD) return 'Trade'

  return ''
}

export const getTransactionEventsByType = (type: string): number[] => {
  if (type === 'add') return [TransactionEvent.MINT]
  if (type === 'redeem') return [TransactionEvent.REDEEM]
  if (type === 'remove') return [TransactionEvent.BURN]
  if (type === 'trade') return [TransactionEvent.BUY_SPEAR, TransactionEvent.BUY_SHIELD]

  return [
    TransactionEvent.MINT,
    TransactionEvent.BURN,
    TransactionEvent.BUY_SPEAR,
    TransactionEvent.BUY_SHIELD,
    TransactionEvent.REDEEM,
  ]
}

export const isTradeTransaction = (event: number): boolean => {
  return event === TransactionEvent.BUY_SPEAR || event === TransactionEvent.BUY_SHIELD
}
