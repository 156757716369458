import React from 'react'
import { styled, Select } from '@mui/material'

const SelectWrapper = styled(Select)`
  border-radius: 8px;
  color: #fff;
  max-height: 52px;
  font-size: 14px;
  div,
  p {
    display: flex;
    align-items: center;
    line-height: 15px;
    font-size: 15px;
  }
  img {
    margin-right: 7px;
  }
  svg {
    width: 20px;
    /* height: 25px; */
  }
  > div {
    /* padding: 15px 10px 10px; */
    display: flex;
    /* padding-left: props => props.type === hasicon && 40px; */
    align-items: center;
    justify-content: space-between;
  }
  span {
    line-height: normal;
  }
  input {
    height: inherit;
  }
  .MuiOutlinedInput-notchedOutline {
    top: -10px;
  }
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(151, 151, 151, 0.3);
    }
  }
`

const DivSelect = (props: any) => {
  return (
    <SelectWrapper
      onOpen={() => {
        document.querySelector('body')?.removeAttribute('id')
        document.querySelector('body')?.setAttribute('id', 'dof')
      }}
      onClose={() => {
        document.querySelector('body')?.setAttribute('id', 'body')
        document.querySelector('body')?.removeAttribute('id')
      }}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      value={props.value}
      onChange={props.change}
      style={props.styles}
      displayEmpty
      id="simple-select"
      sx={props?.sx}
      MenuProps={{
        style: { maxHeight: 270 },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {props.children}
    </SelectWrapper>
  )
}
export default DivSelect
