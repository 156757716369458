import React from 'react'

import BTCLOGO from '../assets/images/btc.png'
import ETHLOGO from '../assets/images/eth.png'
import DOGELOGO from '../assets/images/doge.png'

export const UnderlyingIcon = ({ name, width, height }: { name: string; width: number; height: number }) => {
  return (
    <img
      width={width ?? 16}
      height={height ?? 16}
      src={name === 'BTC' ? BTCLOGO : name === 'ETH' ? ETHLOGO : DOGELOGO}
      alt="logo"
    />
  )
}
