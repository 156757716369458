import gql from 'graphql-tag'
import { currentTimestamp } from 'utils'
import { client } from 'apollo/client'
import BigNumber from 'bignumber.js'
import { Battle } from '../../types/battle'
import { ChartDayData, TransactionEvent } from '../../types'
import { unixToDate } from '../../utils/date'
import { getPriceFromSqrtPriceX96 } from '@divergence-protocol/diver-sdk'
import dayjs from 'dayjs'

export const getValueByDay = (battle: any) => {
  const filterLiquidityMap = battle.battleDayDatas.reduce((pv: any, cv: any, ci: number) => {
    pv[cv.date] = {
      totalVolumeUSD: cv.totalVolumeUSD,
      totalAmountUSD: cv.totalAmountUSD,
      feesUSD: cv.totalFeesUSD,
      spearOpenInterest: cv.spearOpenInterest,
      shieldOpenInterest: cv.shieldOpenInterest,
    }

    return pv
  }, {})

  const filterLiquidityMapKey = Object.keys(filterLiquidityMap)
  const dayNumber =
    (Number(filterLiquidityMapKey[filterLiquidityMapKey.length - 1]) - Number(filterLiquidityMapKey[0])) / 86400 + 1
  const firstDayTimestamp = filterLiquidityMapKey[0]
  for (let i = 0; i < dayNumber; i++) {
    if (!filterLiquidityMap[Number(firstDayTimestamp) + 86400 * i]) {
      filterLiquidityMap[Number(firstDayTimestamp) + 86400 * i] = {
        totalVolumeUSD: 0,
        totalAmountUSD: 0,
        feesUSD: 0,
        spearOpenInterest: 0,
        shieldOpenInterest: 0,
      }
    }
  }
  const today = dayjs().startOf('day').unix()
  for (let i = today; i >= Number(firstDayTimestamp); i = i - 86400) {
    if (!filterLiquidityMap[i]) {
      filterLiquidityMap[i] = {
        totalVolumeUSD: 0,
        totalAmountUSD: 0,
        feesUSD: 0,
        spearOpenInterest: 0,
        shieldOpenInterest: 0,
      }
    }
  }

  return Object.entries(filterLiquidityMap).map((result: any) => {
    return {
      date: result[0],
      totalVolumeUSD: result[1].totalVolumeUSD,
      totalAmountUSD: result[1].totalAmountUSD,
      feesUSD: result[1].feesUSD,
      spearOpenInterest: result[1].spearOpenInterest,
      shieldOpenInterest: result[1].shieldOpenInterest,
    }
  })
}

export const getTVLByDay = (battle: any) => {
  const value = getValueByDay(battle)
  return value.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    let tvl = 0
    for (let i = 0; i <= ci; i++) {
      tvl += Number(array[i].totalAmountUSD)
    }
    const data = {
      time: unixToDate(cV.date),
      value: tvl,
    }
    pV.push(data)
    return pV
  }, [])
}

export const getOpenSpearInterestByDay = (battle: any) => {
  const value = getValueByDay(battle)

  return value.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    if (Number(cV.spearOpenInterest) === 0) {
      const last = unixToDate(cV.date - 24 * 3600)
      const lastItem = pV?.find((p: any) => p.time === last)
      pV.push({
        time: unixToDate(cV.date),
        value: lastItem?.value,
      })
    } else {
      pV.push({
        time: unixToDate(cV.date),
        value: Number(cV.spearOpenInterest),
      })
    }
    return pV
  }, [])
}

export const getOpenShieldInterestByDay = (battle: any) => {
  const value = getValueByDay(battle)
  return value.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    if (Number(cV.shieldOpenInterest) === 0) {
      const last = unixToDate(cV.date - 24 * 3600)
      const lastItem = pV?.find((p: any) => p.time === last)
      pV.push({
        time: unixToDate(cV.date),
        value: lastItem?.value,
      })
    } else {
      pV.push({
        time: unixToDate(cV.date),
        value: Number(cV.shieldOpenInterest),
      })
    }
    return pV
  }, [])
}

export const getVolumeByDay = (battle: any) => {
  const value = getValueByDay(battle)
  return value.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    // let tvl = 0
    // for (let i = 0; i <= ci; i++) {
    //   tvl += Number(array[i].totalVolumeUSD)
    // }
    const data = {
      time: unixToDate(cV.date),
      value: cV.totalVolumeUSD,
    }
    pV.push(data)
    return pV
  }, [])
}

export const getFeesByDay = (battle: any) => {
  const value = getValueByDay(battle)
  return value.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    // let tvl = 0
    // for (let i = 0; i <= ci; i++) {
    //   tvl += Number(array[i].feesUSD)
    // }
    const data = {
      time: unixToDate(cV.date),
      value: cV.feesUSD,
    }
    pV.push(data)
    return pV
  }, [])
}

export const BATTLE_DATA = () => {
  return gql`
    query BattleDatas($dayTs: Int!, $hourTs: Int!, $startTs: Int!) {
      battles(where: { startTS_gt: $startTs }) {
        id
        underlying {
          symbol
        }
        collateral {
          symbol
        }
        endTS
        totalCollateralUSD
        strikeValue
        sqrtPriceX96
        result
        volumeUSD
        amountUSD
        spearOpenInterest
        shieldOpenInterest
        battleDayDatas(where: { date_gte: $dayTs }) {
          date
          totalFeesUSD
          totalVolumeUSD
          spearOpenInterest
          shieldOpenInterest
        }
        battleHourDatas(where: { hour_gte: $hourTs }) {
          totalAmountUSD
          totalVolumeUSD
          totalFeesUSD
        }
        adds {
          id
          sender
          time
          collateralAmount
          spearAmount
          shieldAmount
          liquidityType
          battle {
            sqrtPriceX96
          }
        }
        removes {
          id
          sender
          time
          collateralAmount
          spearAmount
          shieldAmount
          liquidityType
          battle {
            sqrtPriceX96
          }
        }
        redeems {
          id
          sender
          time
          collateralAmount
          battle {
            sqrtPriceX96
          }
        }
        trades {
          id
          sender
          amountIn
          amountOut
          tradeType
          time
        }
      }
    }
  `
}

const time24H = Number((currentTimestamp() / 1000 - 24 * 3600).toFixed(0))
const time7D = Number((currentTimestamp() / 1000 - 24 * 3600 * 30).toFixed(0))

export async function fetchBattleData() {
  const battleData = {
    battleDayData: [],
    battleHourData: [],
    battles: [],
  }
  let error = false
  let allFound = false
  try {
    while (!allFound) {
      const { loading, error, data } = await client.query({
        query: BATTLE_DATA(),
        variables: {
          hourTs: time24H,
          dayTs: time7D,
          startTs: time7D,
        },
        // pollInterval: 2000,
        fetchPolicy: 'network-only',
      })
      if (!loading) {
        if (data.battles.length < 100) {
          allFound = true
        }
        if (data) {
          battleData.battles = battleData.battles.concat(data.battles)
        }
      }
    }
  } catch {
    error = true
  }
  const anyError = Boolean(false)
  const anyLoading = Boolean(false)

  const battles: Battle[] = []
  if (battleData.battles) {
    battleData.battles.map((battle: any) => {
      const volumeUSD7D = battle.battleDayDatas.reduce((currentVolume7D: string, dayData: any) => {
        currentVolume7D = new BigNumber(currentVolume7D).plus(dayData.totalVolumeUSD).toString()
        return currentVolume7D
      }, '0')

      const volumeUSD24H = battle.battleHourDatas.reduce((currentVolume24H: string, hourData: any) => {
        currentVolume24H = new BigNumber(currentVolume24H).plus(hourData.totalVolumeUSD).toString()
        return currentVolume24H
      }, '0')

      const feesUSD24H = battle.battleHourDatas.reduce((current: string, hourData: any) => {
        current = new BigNumber(current).plus(hourData.totalFeesUSD).toString()
        return current
      }, '0')

      const tvlByDay = getTVLByDay(battle)
      const volumeByDay = getVolumeByDay(battle)
      const feesByDay = getFeesByDay(battle)
      const spearOpenDay = getOpenSpearInterestByDay(battle)
      const shieldOpenDay = getOpenShieldInterestByDay(battle)

      const txs = []
      txs.push(
        ...battle.adds.map((add: any) => {
          const shieldPrice = getPriceFromSqrtPriceX96(add.battle.sqrtPriceX96).toFixed(6)
          const spearPrice = new BigNumber(1).minus(shieldPrice).toFixed(6)
          return {
            hash: add.id,
            account: add.sender,
            totalValue: `${
              add.liquidityType === 0
                ? new BigNumber(add.collateralAmount).toFixed(6) + ' ' + battle?.collateral?.symbol
                : add.liquidityType === 1
                ? new BigNumber(spearPrice).multipliedBy(add.spearAmount).toFixed(6) + ' ' + battle?.collateral?.symbol
                : new BigNumber(shieldPrice).multipliedBy(add.shieldAmount).toFixed(6) +
                  ' ' +
                  battle?.collateral?.symbol
            }`,
            totalAmount: `${
              add.liquidityType === 0
                ? new BigNumber(add.collateralAmount).toFixed(6) + ' ' + battle?.collateral?.symbol
                : add.liquidityType === 1
                ? new BigNumber(add.spearAmount).toFixed(6) + ' Spear'
                : new BigNumber(add.shieldAmount).toFixed(6) + ' Shield'
            }`,
            event: TransactionEvent.MINT,
            time: add.time,
            liquidityType: add.liquidityType,
          }
        })
      )
      txs.push(
        ...battle.removes.map((add: any) => {
          const shieldPrice = getPriceFromSqrtPriceX96(add.battle.sqrtPriceX96).toFixed(6)
          const spearPrice = new BigNumber(1).minus(shieldPrice).toFixed(6)

          const totalAmount = () => {
            if (add.liquidityType === 0) {
              return new BigNumber(add.collateralAmount).toFixed(6) + ' ' + battle?.collateral?.symbol
            }

            if (add.liquidityType === 1) {
              let s = ''
              if (new BigNumber(add.collateralAmount).gt(0.1)) {
                s += new BigNumber(add.collateralAmount).toFixed(6) + ` ${battle?.collateral?.symbol} + `
              }
              return s + new BigNumber(add.spearAmount).toFixed(6) + ' SPEAR'
            }

            if (add.liquidityType === 2) {
              let s = ''
              if (new BigNumber(add.collateralAmount).gt(0)) {
                s += new BigNumber(add.collateralAmount).toFixed(6) + ` ${battle?.collateral?.symbol} `
              }
              return s + new BigNumber(add.spearAmount).toFixed(6) + ' SHIELD'
            }

            return ''
          }
          const totalValue = () => {
            if (add.liquidityType === 0) {
              return new BigNumber(add.collateralAmount).toFixed(6) + ' ' + battle?.collateral?.symbol
            }

            if (add.liquidityType === 1) {
              return (
                new BigNumber(spearPrice).multipliedBy(add.spearAmount).plus(add.collateralAmount).toFixed(6) +
                ' ' +
                battle?.collateral?.symbol
              )
            }

            if (add.liquidityType === 2) {
              return (
                new BigNumber(shieldPrice).multipliedBy(add.shieldAmount).plus(add.collateralAmount).toFixed(6) +
                ' ' +
                battle?.collateral?.symbol
              )
            }

            return ''
          }
          return {
            hash: add.id,
            account: add.sender,
            totalValue: `${totalValue()}`,
            totalAmount: `${totalAmount()}`,
            event: TransactionEvent.BURN,
            time: add.time,
            liquidityType: add.liquidityType,
          }
        })
      )
      txs.push(
        ...battle.redeems.map((r: any) => {
          return {
            hash: r.id,
            account: r.sender,
            totalValue: `${new BigNumber(r.collateralAmount).toFixed(6)} ${battle?.collateral?.symbol}`,
            totalAmount: `${new BigNumber(r.collateralAmount).toFixed(6)} ${battle?.collateral?.symbol}`,
            event: TransactionEvent.REDEEM,
            time: r.time,
          }
        })
      )
      txs.push(
        ...battle.trades.map((add: any) => {
          return {
            hash: add.id,
            account: add.sender,
            totalValue: `${new BigNumber(add.amountIn).toFixed(6)} ${battle?.collateral?.symbol}`,
            totalAmount: `${new BigNumber(add.amountOut).toFixed(6)} ${add.tradeType === 0 ? 'Spear' : 'Shield'}`,
            event: add.tradeType === 0 ? TransactionEvent.BUY_SPEAR : TransactionEvent.BUY_SHIELD,
            time: add.time,
            liquidityType: 100,
          }
        })
      )

      battles.push({
        address: battle.id,
        underlyingId: battle.underlying.symbol,
        collateralSymbol: battle.collateral.symbol,
        strikeValue: battle.strikeValue,
        liquidity: Number(battle.amountUSD),
        volumeUSD7D: Number(volumeUSD7D),
        volumeUSD24H: Number(volumeUSD24H),
        feesUSD24H: Number(feesUSD24H),
        sqrtPriceX96: battle.sqrtPriceX96,
        dayData: battle.battleDayDatas,
        endTS: battle.endTS,
        tvlByDay: tvlByDay,
        volumeByDay: volumeByDay,
        spearOpenInterestByDay: spearOpenDay,
        shieldOpenInterestByDay: shieldOpenDay,
        feesByDay: feesByDay,
        premium: 222,
        totalCollateralUSD: Number(battle.totalCollateralUSD),
        premiumUSD: Number(battle.premiumUSD),
        spearOpenInterest: battle?.spearOpenInterest,
        shieldOpenInterest: battle?.shieldOpenInterest,
        transactions: txs,
      })
    })
  }

  return { anyLoading, error: anyError, data: battles }
}
