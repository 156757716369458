import gql from 'graphql-tag'
import { ArenaData } from '../../state/arena/reducer'
import { ChartDayData } from '../../types'
import { client } from '../../apollo/client'

export const ARENA_DATA = () => {
  return gql`
    query volume24h {
      diverDayDatas {
        date
        totalVolumeUSD
        totalAmountUSD
      }
    }
  `
}

export async function fetchArenaData() {
  const { loading, error, data } = await client.query({
    query: ARENA_DATA(),
    fetchPolicy: 'network-only',
  })

  let hadError = false
  if (error) {
    console.log(`fetchData Error ${error}`)
    hadError = true
  }

  let dayDatas: ChartDayData[] = []
  dayDatas = data?.diverDayDatas.reduce((pV: any, cV: any) => {
    const dayData: ChartDayData = {
      date: cV.date,
      totalVolumeUSD: parseFloat(cV.totalVolumeUSD),
      totalAmountUSD: parseFloat(cV.totalAmountUSD),
      shieldOpenInterest: 0,
      spearOpenInterest: 0,
    }
    pV.push(dayData)
    return pV
  }, [])

  const result: ArenaData = {
    dayData: dayDatas,
    hourData: dayDatas,
  }

  return { loading, error: hadError, data: result }
}
