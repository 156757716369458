import { fetchArenaData } from 'data/arena/overview'
import { useEffect, useState } from 'react'
import { useArenaData } from './hooks'
import useRefresh from '../../hooks/useRefresh'

export default function Updater(): null {
  const { fastRefresh } = useRefresh()
  const [arenaData, updateArenaData] = useArenaData()
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetch() {
      const { data: fetchedBattleData, error, loading } = await fetchArenaData()
      if (!error && !loading && fetchedBattleData) {
        updateArenaData(fetchedBattleData)
      }
      if (error) {
        setError(error)
      }
    }
    if (!error) {
      fetch()
    }
  }, [fastRefresh])

  return null
}
