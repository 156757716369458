import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components'

import EthLogo from '../../assets/images/eth.png'
import LogoDark from '../../assets/svg/logo_white.svg'
import ArbirtumLogo from '../../assets/svg/arbirtum_logo.svg'
import { getQueryString } from 'utils/'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'

import { RowFixed } from '../Row'

const HeaderFrame = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.7rem 0;
  z-index: 2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  // background: rgba(0, 0, 0, 0.3);
  ${({ theme }) => theme.mediaWidth.upToMedium`
    // grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

export default function Header() {
  const [currChain, setCurrChain] = useState('')
  useMemo(() => {
    if (getQueryString('c') !== '') {
      const chain = getQueryString('c')
      setCurrChain(chain)
    } else {
      setCurrChain('Arbitrum Rinkeby')
    }
  }, [])
  const setChainNetwork = (n: string) => {
    window.location.href = window.location.href.split('?')[0] + '?c=' + n
    window.location.reload()
  }

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title to={`/`}>
          <UniIcon>
            <img width={'170px'} src={LogoDark} alt="logo" />
          </UniIcon>
        </Title>
      </HeaderRow>
      <Menu
        align={'end'}
        position={'anchor'}
        className="menu"
        menuButton={
          <MenuButton className="menuButton">
            {currChain === 'Kovan' && <img width="16px" src={EthLogo} alt="eth" />}
            {currChain === 'Arbitrum Rinkeby' && <img width="16px" src={ArbirtumLogo} alt="Arbirtum" />}
          </MenuButton>
        }
      >
        <MenuItem onClick={() => setChainNetwork('Arbitrum Rinkeby')}>
          <img width="16px" src={ArbirtumLogo} alt="Arbirtum" />
          <span>Arbirtum Rinkeby</span>
        </MenuItem>
      </Menu>
    </HeaderFrame>
  )
}
