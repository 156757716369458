import { createReducer } from '@reduxjs/toolkit'
import { currentTimestamp } from 'utils'
import { updateBattles } from './actions'
import { Battle } from '../../types/battle'

export interface BattleDayHour {
  addr: string
  date: number
  volumeUSD: number
  liquidityUSD: number
}

export interface BattleData {
  data: Battle[]
  dayData: BattleDayHour[]
  hourData: BattleDayHour[]
}

export interface BattleState {
  readonly data: Battle[] | undefined
  readonly lastUpdated: number | undefined
}

export const initialState: BattleState = {
  data: undefined,
  lastUpdated: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(updateBattles, (state, { payload: { data } }) => {
    state.data = data
    state.lastUpdated = currentTimestamp()
  })
})
