import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { DarkGreyCard } from 'components/Card'
import Loader from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { getCoinPrice } from 'utils/index'
import { ClickableText, Label } from 'components/Text'
import { Arrow, Break, PageButtons } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { usePrices } from 'state/price/hooks'
import PicSpear from '../../assets/svg/spear.svg'
import PicShield from '../../assets/svg/shield.svg'
import { Battle } from '../../types/battle'
import { getPriceFromSqrtPriceX96 } from '@divergence-protocol/diver-sdk'
import BigNumber from 'bignumber.js'
import { LinkWrapper } from '../../components/pools/PoolTable'
import { isMobile } from 'react-device-detect'

const Wrapper = styled(DarkGreyCard)`
  overflow-x: auto;
  width: 100%;
  border-radius: 6px;
`

const TableBox = styled(AutoColumn)`
  min-width: 1260px;
  max-height: 574px;
  overflow: auto;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #6a708b;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #121a27;
    border-radius: 10px;
    margin: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #373d57;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: flex-start;
  grid-template-columns: 100px 1fr repeat(4, 1fr);
`
const ResponsiveGridMobile = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: flex-start;
  grid-template-columns: 100px 1fr repeat(1, 1fr);
`
const HeaderGrid = styled(isMobile?ResponsiveGridMobile:ResponsiveGrid)`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #041c32;
`

const SORT_FIELD = {
  volumeUSD: 'volumeUSD',
  liquidityUSD: 'liquidityUSD',
  strikePrice: 'strikePrice',
  spearPrice: 'spearPrice',
  shieldPrice: 'shieldPrice',
  spearSold: 'spearSold',
  shieldSold: 'shieldSold',
  liquidityCollateral: 'liquidityCollateral',
  strikePriceUnder: 'strikePriceUnder',
  strikePriceOver: 'strikePriceOver',
  price: 'price',
}

// const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
const DataRow = ({ poolData, index }: { poolData: Battle; index: number }) => {
  const prices = usePrices()
  const price = useMemo(() => {
    return getCoinPrice(prices, poolData.collateralSymbol)
  }, [prices, poolData])
  return (
    <LinkWrapper to={`/pd/${poolData.address}`}>
      {isMobile?<ResponsiveGridMobile>
        <Label width={'120px'} color={'#fff'} fontWeight={400} padding="10px 0 3px" marginLeft="-5px">
          <RowFixed>
            <TYPE.label fontFamily={'Roboto'} color={'#fff'} ml="8px">
              {poolData.strikeValue}
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400} padding="7px 0 2px" minWidth="170px">
          <RowFixed>
            <TYPE.label display="flex">
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="20px" src={PicSpear} style={{ marginRight: '5px' }} />
                {new BigNumber(1).minus(getPriceFromSqrtPriceX96(poolData.sqrtPriceX96)).toFixed(6)}
              </div>
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="20px" src={PicShield} style={{ marginRight: '5px' }} />
                {getPriceFromSqrtPriceX96(poolData.sqrtPriceX96).toFixed(6)}
              </div>
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400} padding="10px 0 3px">
          ${new BigNumber(poolData.volumeUSD24H).toFormat(2)}
        </Label>
      </ResponsiveGridMobile> :
      <ResponsiveGrid>
        <Label width={'120px'} color={'#fff'} fontWeight={400} padding="10px 0 3px" marginLeft="-5px">
          <RowFixed>
            <TYPE.label fontFamily={'Roboto'} color={'#fff'} ml="8px">
              {poolData.strikeValue}
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400} padding="7px 0 2px" minWidth="170px">
          <RowFixed>
            <TYPE.label display="flex">
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="20px" src={PicSpear} style={{ marginRight: '5px' }} />
                {new BigNumber(1).minus(getPriceFromSqrtPriceX96(poolData.sqrtPriceX96)).toFixed(6)}
              </div>
              <div style={{ minWidth: '90px', display: 'flex', alignItems: 'center' }}>
                <img width="20px" src={PicShield} style={{ marginRight: '5px' }} />
                {getPriceFromSqrtPriceX96(poolData.sqrtPriceX96).toFixed(6)}
              </div>
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400} padding="10px 0 3px">
          {new BigNumber(poolData.spearOpenInterest).lt(0)
            ? '0.000'
            : new BigNumber(poolData.spearOpenInterest).toFormat(6)}
        </Label>
        <Label end={1} fontWeight={400} padding="10px 0 3px">
          {new BigNumber(poolData.shieldOpenInterest).lt(0)
            ? '0.000'
            : new BigNumber(poolData.shieldOpenInterest).toFormat(6)}
        </Label>
        <Label end={1} fontWeight={400} padding="10px 0 3px">
          ${new BigNumber(poolData.volumeUSD24H).toFormat(2)}
        </Label>
        <Label end={1} fontWeight={400} padding="10px 0 3px">
          ${new BigNumber(poolData.volumeUSD7D).toFormat(2)}
        </Label>
      </ResponsiveGrid>}
    </LinkWrapper>
  )
}

const MAX_ITEMS = 10

export default function BattleTable({ poolDatas, maxItems = MAX_ITEMS }: { poolDatas: Battle[]; maxItems?: number }) {
  const theme = useTheme()
  const [sortField, setSortField] = useState(SORT_FIELD.liquidityCollateral)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          .filter((x) => !!x && !POOL_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof Battle] > b[sortField as keyof Battle]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!poolDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      <TableBox gap="8px" style={{ minWidth: isMobile?'auto':'1260px',paddingRight: isMobile?'0':'20px'}}>
        <HeaderGrid>
          <ClickableText
            style={{ width: '120px', padding: '5px 0' }}
            color={theme.text3}
            onClick={() => handleSort(SORT_FIELD.price)}
          >
            Strike Price {arrow(SORT_FIELD.price)}
          </ClickableText>
          <ClickableText minWidth="120px" style={{ padding: '5px 20px 5px 0px' }} color={theme.text3} end={1}>
            Option Price
          </ClickableText>
          {isMobile&&<ClickableText style={{ padding: '5px 0' }} color={theme.text3} end={1}>
            &nbsp; Volume 24H {arrow(SORT_FIELD.shieldSold)}
          </ClickableText>}
          {!isMobile&&<ClickableText style={{ padding: '5px 0' }} color={theme.text3} end={1}>
            Spear Open Interest
          </ClickableText>}
          {!isMobile&&<ClickableText style={{ padding: '5px 0' }} color={theme.text3} end={1}>
            Shield Open Interest
          </ClickableText>}
          {!isMobile&&<ClickableText style={{ padding: '5px 0' }} color={theme.text3} end={1}>
            &nbsp; Volume 24H {arrow(SORT_FIELD.shieldSold)}
          </ClickableText>}
          {!isMobile&&<ClickableText style={{ padding: '5px 0' }} color={theme.text3} end={1}>
            &nbsp; Volume 7D {arrow(SORT_FIELD.spearSold)}
          </ClickableText>}
        </HeaderGrid>
        <Break />
        {sortedPools.length > 0 ? (
          sortedPools.map((poolData, i) => {
            if (poolData) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })
        ) : (
          <div style={{ textAlign: 'center', padding: '60px 0' }}>No Available Pools</div>
        )}
        {sortedPools.length > 0 && (
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage}>→</Arrow>
            </div>
          </PageButtons>
        )}
      </TableBox>
    </Wrapper>
  )
}
