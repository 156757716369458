import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { updateBattles } from './actions'
import { Battle } from '../../types/battle'

export function useBattleData(): [Battle[] | undefined, (battleData: Battle[]) => void] {
  const battles = useSelector((state: AppState) => state.battle.data)
  const dispatch = useDispatch<AppDispatch>()
  const setBattleData: (battleData: Battle[]) => void = useCallback(
    (data: Battle[]) => dispatch(updateBattles({ data })),
    [dispatch]
  )
  return [battles, setBattleData]
}

export function useBattleLoad() {
  const battleDataLoad = useSelector((state: AppState) => state.battle.data)
  return !!battleDataLoad?.length
}
