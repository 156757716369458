const arbitrum = { id: 42161 }

export const COLLATERALS = [
  {
    name: 'USDT',
    chainId: arbitrum.id,
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    decimals: 6,
  },
  {
    name: 'DIVER',
    chainId: arbitrum.id,
    address: '0x5aCAB15Fe93127f550b17eacdF529bc7d3b57e2E',
    decimals: 18,
  },
  {
    name: 'DITANIC',
    chainId: arbitrum.id,
    address: '0x8C2F032c62A59743aAE9B4925f72Ad06ffc4B498',
    decimals: 18,
  },
  {
    name: 'WETH',
    chainId: arbitrum.id,
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    decimals: 18,
  },
]
