import React, { Suspense, useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Protocol from './Protocol'
import PoolsOverview from './Pool/PoolsOverview'
import { LocalLoader } from 'components/Loader'
import { ExternalLink, TYPE } from 'theme'
import { useSubgraphStatus } from 'state/application/hooks'
import { DarkGreyCard } from 'components/Card'
import BattleInfo from './BattleInfo'
import BattleInfoDetails from './BattleInfo/details'
import lbPic from '../assets/images/global_bottom1.png'
import rbPic from '../assets/images/global_bg2.png'
import { ThemeProvider } from '@mui/material'
import { theme } from 'theme/theme'
import { isMobile } from 'react-device-detect'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-height: 100vh;
  position: relative;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
  background-color: ${({ theme }) => theme.bg6};
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  margin-top: 100px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 2rem;
    margin-top: 100px;
  `};

  z-index: 1;

  > * {
    max-width: 1280px;
  }
`

const LFireWrapper = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  img {
    margin-bottom: -10px;
  }
`
const RFireWrapper = styled.div`
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  text-align: right;
  img {
    margin-bottom: -10px;
  }
`

export default function App() {
  // pretend load buffer
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1300)
  }, [])

  // subgraph health
  const [subgraphStatus] = useSubgraphStatus()

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        {loading ? (
          <LocalLoader fill={true} />
        ) : subgraphStatus.available === false ? (
          <AppWrapper>
            <BodyWrapper>
              <DarkGreyCard style={{ maxWidth: '340px' }}>
                <TYPE.label>
                  The Graph network which provides data for this site is temporarily down. Check status{' '}
                  <ExternalLink href="https://thegraph.com/explorer/subgraph/ianlapham/uniswap-v3-testing">
                    here.
                  </ExternalLink>
                </TYPE.label>
              </DarkGreyCard>
            </BodyWrapper>
          </AppWrapper>
        ) : (
          <AppWrapper>
            <LFireWrapper>
              <img width="50%" src={lbPic} alt="" />
            </LFireWrapper>
            <RFireWrapper>
              <img width="30%" src={rbPic} alt="" />
            </RFireWrapper>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper style={{ marginTop:isMobile?'40px':'100px'}}>
              <Switch>
                <Route exact strict path="/">
                  <Redirect to={'/pools/0/BTC/0/USDT?c=42161'} />
                  {/*<Redirect to={'/pools/0/BTC/0/USDT?c=421611'} />*/}
                </Route>
                <Route exact strict path="/protocol" component={Protocol} />
                <Route exact strict path="/pools" component={PoolsOverview} />
                <Route exact strict path="/pd/:id" component={BattleInfoDetails} />
                <Route
                  exact
                  strict
                  path="/pools/:strikeMode/:underlying/:periodType/:collateral"
                  component={BattleInfo}
                />
              </Switch>
            </BodyWrapper>
            <div style={{ height: '30px' }} />
          </AppWrapper>
        )}
      </ThemeProvider>
    </Suspense>
  )
}
