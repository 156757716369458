export const getUnderlyingPrice = (prices: any, underlying: string) => {
  switch (underlying) {
    case 'BTC':
      return prices?.[0]?.price
    case 'ETH':
      return prices?.[1]?.price
    case 'DOGE':
      return prices?.[2]?.price
    case 'GME':
      return prices?.[3]?.price
    default:
      return prices?.[0]?.price
  }
}
