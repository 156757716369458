import { createReducer } from '@reduxjs/toolkit'
import { ChartDayData } from 'types'
import { currentTimestamp } from 'utils'
import { updateData } from './actions'

export interface ArenaData {
  dayData: ChartDayData[]
  hourData: ChartDayData[]
}

export interface ArenaState {
  readonly lastUpdated: number | undefined

  readonly data: ArenaData | undefined
}

export const initialState: ArenaState = {
  data: undefined,
  lastUpdated: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(updateData, (state, { payload: { data } }) => {
    state.data = data
    state.lastUpdated = currentTimestamp()
  })
})
